/** @format */

import env from '@/env';
import { NotificationError } from '@commons/components/notifications';
import { TypeAttendee } from '@helpers/types';
import { useQuery } from 'react-query';
import { apiGetAttendee } from '../../services/apiGetAttendee';

async function apiRequestGetAttendee(id: string, meetingId: string, token: string) {
  const res: TypeAttendee = await apiGetAttendee(id, meetingId, token);
  return res;
}
export function useGetAttendee(id: string, meetingId: string, token: string) {
  return useQuery(
    ['getAttendee', id, meetingId],
    async () => {
      if (id && meetingId) {
        const result = await apiRequestGetAttendee(id, meetingId, token);
        return result;
      }
    },
    {
      onError: (err) => {
        window.location.replace(env.urlFrontend);
        NotificationError('会話は存在しません。ホーム画面に移動します。');
      },
      retry: false,
    },
  );
}
