/** @format */

import env from '@/env';
import { NotificationError } from '@commons/components/notifications';
import { TypeMeeting } from '@helpers/types';
import { useQuery } from 'react-query';
import { apiGetMeeting } from '../../services/apiGetMeeting';
import { clearLocalStorage } from '@helpers/time';

async function apiRequestGetMeeting(id: string, token: string) {
  const res: TypeMeeting = await apiGetMeeting(id, token);
  return res;
}
export function useGetMeeting(id: string, token: string) {
  return useQuery(
    ['getMeeting', id],
    async () => {
      const result = await apiRequestGetMeeting(id, token);
      return result;
    },
    {
      onError: (err) => {
        clearLocalStorage(id);
        window.location.replace(env.urlFrontend);
        NotificationError('会話は存在しません。ホーム画面に移動します。');
      },
      retry: false,
    },
  );
}
