/** @format */

export interface Type {
  demo: string;
}
export interface TypeMeetingInfo {
  meeting_id: string;
  media_placement: {
    audio_host_url: string;
    audio_fallback_url: string;
    event_ingestion_url: string;
    screen_data_url: string;
    screen_sharing_url: string;
    screen_viewing_url: string;
    signaling_url: string;
    turn_control_url: string;
  };
  media_region: string;
}

export interface TypeZweiMeetingInfo {
  member_id: string;
  partner_id: string;
  matchmaking_at: string;
  detail_page_url: string;
  my_id: string;
  url_redirect: string;
}

export interface IIUser {
  academic_level: string;
  address: string;
  age: string;
  company: string;
  job: string;
  avatar: string;
  member_number: string;
  name: string;
  school: string;
  staff_phone: string;
  time_end?: string;
}
export interface IUser {
  user?: IIUser;
  partner?: IIUser;
  back_url?: string;
  staff_phone_number?: string;
}
export interface TypeAttendeeInfo {
  attendee_id: string;
  external_user_id: string;
  join_token: string;
}

export interface InfoNetWork {
  status?: number;
  message?: string;
}
export interface TypeMeeting extends InfoNetWork {
  meeting_info: TypeMeetingInfo;
  staff_phone_number: string;
  zwei_meeting_info: TypeZweiMeetingInfo;
}

export interface TypeAttendeeRequest {
  attendee_info: TypeAttendeeInfo;
  application_metadata: IUser;
}
export interface TypeAttendee extends InfoNetWork {
  attendee: TypeAttendeeRequest;
}

export enum TypeStatusDevices {
  NG = '異常',
  OK = '正常',
  WAIT = '未テスト',
  LOADING = 'LOADING',
}
export interface TypeDataRequestMeeting {
  meetingInfo: {
    MeetingId: string;
    MediaPlacement: {
      AudioHostUrl: string;
      AudioFallbackUrl: string;
      ScreenDataUrl: string;
      ScreenSharingUrl: string;
      ScreenViewingUrl: string;
      SignalingUrl: string;
      TurnControlUrl: string;
      EventIngestionUrl: string;
    };
    MediaRegion: string;
  };
  attendeeInfo: {
    ExternalUserId: string;
    AttendeeId: string;
    JoinToken: string;
    // deprecated, use zwei_meeting_info.url_redirect instead
    BackUrl: string;
  };
  zwei_meeting_info?: TypeZweiMeetingInfo;
}

export interface RequestIdInfo {
  requestId: string;
  timeStart: Time;
}

export interface Time {
  years: number;
  months: number;
  date: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

export enum OS {
  IOS = 'iOS',
  ANDROID = 'AndroidOS',
  BLACKBERRY = 'BlackBerryOS',
  WINDOWS = 'WindowsOS',
  MAC = 'MacOS',
}
