import React from 'react';
import { useSearchParams } from 'react-router-dom';
import '@modules/Meetings/styles/notify-second.scss'; import {
  useToggleLocalMute,
  useLocalVideo
} from 'amazon-chime-sdk-component-library-react';
import { useCountUpNumber } from '../hooks/useCountUpNumber'
import { clearLocalStorage } from '@helpers/time';
// import { useMeeting } from '../../MeetingConfirm/hooks/useJoinMeeting/useMeeting';
interface IProps {
  setIsShowEndMeeting: React.Dispatch<React.SetStateAction<boolean>>
}
function NotifySecond({ setIsShowEndMeeting }: IProps) {
  const { timeLeft, TIME_END_NOTIFY, isOpenNotify } = useCountUpNumber();
  const { minutes, seconds } = timeLeft;
  const [searchParams] = useSearchParams();
  const request_id = searchParams.get('request_id');
  const { muted, toggleMute } = useToggleLocalMute();
  const { toggleVideo, isVideoEnabled } = useLocalVideo();

  React.useEffect(() => {
    if (timeLeft.minutes === TIME_END_NOTIFY && timeLeft.seconds === TIME_END_NOTIFY && request_id) {
      if (!muted) toggleMute();
      if (isVideoEnabled) toggleVideo();
      setIsShowEndMeeting(true);
      clearLocalStorage(request_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeLeft, request_id]);

  return isOpenNotify ? (
    <div id="notifySecond">
      <div className="notify-container">
        {minutes !== 0 ? `残り時間終了まであと ${minutes} 分です。` : `残り時間終了まであと ${seconds} 秒です。`}
      </div>
    </div>
  ) : <></>;
}

export default NotifySecond;
