/** @format */

import { meetingState } from '@/states/request-meeting';
import '@modules/Meetings/styles/meeting-start.scss';
import {
  LocalVideo,
  RemoteVideo,
  useLocalVideo,
  useMeetingStatus,
  useRemoteVideoTileState,
  useToggleLocalMute,
} from 'amazon-chime-sdk-component-library-react';
import React from 'react';
import ReactLoading from 'react-loading';
import { useRecoilState } from 'recoil';
import { useMeeting } from '../MeetingConfirm/hooks/useJoinMeeting/useMeeting';
import ProfilePage from '../Profiles';
// import CameraOffMeeting from './components/CameraOffMeeting';
import ControlMeeting from './components/ControlMeeting';
import NoRemoteVideoView from './components/NoRemoteVideoView';
import NotifySecond from './components/NotifySecond';
import SlideBurgerMenu from './components/SlideBurgerMenu';
import { useMeetingStart } from './hooks/useMeetingStart';
import ModalEndMeeting from './components/ModalEndMeeting';
import NotifyUserLeft from './components/NotifyUserLeft';
import { getOs } from '@helpers/device';
import { OS } from '@helpers/types';

function MeetingStart() {
  const { loading, leaveMeeting } = useMeeting();
  const { toggleVideo, isVideoEnabled } = useLocalVideo();
  const { muted, toggleMute } = useToggleLocalMute();
  const { tiles } = useRemoteVideoTileState();
  const meetingStatus = useMeetingStatus();
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [profileRandom, setProfileRandom] = React.useState<number>(0);
  const [state_meeting] = useRecoilState(meetingState);
  const refGridContainer = React.useRef<HTMLDivElement>(null);
  const { readyOmiai, isNotifyUserLeft, partnerTileId } = useMeetingStart();
  const [isShowEndMeeting, setIsShowEndMeeting] = React.useState<boolean>(false);

  // const arrData = React.useMemo(() => {
  //   return attendees.map((i) => {
  //     if (i.chimeAttendeeId !== state_meeting?.attendeeInfo.AttendeeId) {
  //       const data = getVideoStatusAttendee(i.chimeAttendeeId);
  //       if (typeof data !== 'number') {
  //         return i.chimeAttendeeId;
  //       } else {
  //         return null;
  //       }
  //     }
  //     return null;
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [attendees]);

  React.useEffect(() => {
    if (!isVideoEnabled && meetingStatus === 1) {
      toggleVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetingStatus]);

  React.useEffect(() => {
    if (refGridContainer && refGridContainer.current) {
      // const width = refGridContainer.current.offsetWidth;
      if (isOpen) {
        // refGridContainer.current.style.justifyContent = 'unset';
        // refGridContainer.current.style.paddingLeft = '150px';
      } else {
        // refGridContainer.current.style.justifyContent = 'center';
        // refGridContainer.current.style.paddingLeft = '0 !important';
      }
    }
  }, [isOpen, refGridContainer]);

  const onViewProfile = () => {
    setIsOpen(!isOpen);
  };
  React.useEffect(() => {
    if (!isOpen) {
      setProfileRandom(profileRandom + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  React.useEffect(() => {
    if (getOs() === OS.IOS) {
      document.body.style.overflow = 'hidden';
    }
    return () => {
      if (document.body.style.overflow === 'hidden') {
        document.body.style.overflow = 'visible';
      }
    }
  }, []);

  const toggleChangeVideo = () => {
    toggleVideo();
  };

  const handleEndMeeting = async () => {
    await leaveMeeting();
    setIsShowEndMeeting(false);
    // jump to anthor page
    window.location.replace(state_meeting?.zwei_meeting_info?.url_redirect || '');
  };

  return (
    <div id="meetingStartPages">
      <div className="meeting-container" style={{ "--vh": `${window.innerHeight * 0.01}px`, overflow: "hidden" } as React.CSSProperties}>
        {readyOmiai ? (
          <div ref={refGridContainer} className="video-title-grid-container">
            <NotifySecond setIsShowEndMeeting={setIsShowEndMeeting} />
            {isNotifyUserLeft && <NotifyUserLeft />}
            <div className="video-container">
              <div className="d-flex flex-row">
                <div className="video-container-left">
                  <RemoteVideo tileId={partnerTileId || 0} className="remote-video" />
                </div>
                <div className="video-container-right">
                  <div className="video-remote-container">
                    <div className="video-remote mb-3">
                      {isVideoEnabled ? <LocalVideo nameplate="" /> : <NoRemoteVideoView text="非表示" />}
                    </div>
                    {tiles
                      ?.filter((i) => i !== partnerTileId)
                      .map((tileId, key) => {
                        return (
                          <div key={key} className="video-remote mb-3">
                            <RemoteVideo tileId={tileId} />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <ControlMeeting
              isVideoEnabled={isVideoEnabled}
              muted={muted}
              isOpen={isOpen}
              onViewProfile={onViewProfile}
              toggleMute={toggleMute}
              toggleVideo={toggleChangeVideo}
              setIsShowEndMeeting={setIsShowEndMeeting}
            />
            <SlideBurgerMenu
              isOpen={isOpen}
              right={true}
              profileRandom={profileRandom}
              iframeSrc={state_meeting?.zwei_meeting_info?.detail_page_url}
            />
            <ModalEndMeeting show={isShowEndMeeting} handleClose={handleEndMeeting} />
          </div>
        ) : (
          <ProfilePage />
        )}
      </div>
      {loading && <ReactLoading className="loading-video-call" type="spokes" color="#009943" />}
    </div>
  );
}

export default MeetingStart;
