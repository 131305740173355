/** @format */

import { TypeDataRequestMeeting } from '@helpers/types';
import { selector } from 'recoil';
import { globalState } from './globalState';

const REQUEST_MEETING = 'REQUEST_MEETING';
export const meetingState = selector<TypeDataRequestMeeting | undefined>({
  key: REQUEST_MEETING,
  get: ({ get }) => {
    const stateGlobal = get(globalState);
    return stateGlobal.data_meeting;
  },
  set: ({ get, set }, newValue) => {
    const state = get(globalState);
    const newDataMeeting = newValue as TypeDataRequestMeeting;
    set(globalState, { ...state, data_meeting: newDataMeeting });
  },
});
