/** @format */

import { CommonPath } from '@commons/common-router';
import { RouteObject } from 'react-router-dom';
import MeetingConfirm from './pages/MeetingConfirm';

export const routerMeetingConfirm: RouteObject = {
  path: CommonPath.MEETING_CONFIRM_PATH,
  element: <MeetingConfirm />,
};
