import React, { useRef } from 'react';
import { meetingState } from '@/states/request-meeting';
import { useRecoilState } from 'recoil';
import { useRosterState, useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { useGetStatusAttendee } from '../../MeetingConfirm/hooks/useGetStatusAttendee/useGetStatusAttendee';
import { apiGetAttendee } from '../../MeetingConfirm/services/apiGetAttendee';

const INTERVAL_TIME = 1000;

export function useMeetingStart() {
  const meetingManager = useMeetingManager();
  const [state_meeting] = useRecoilState(meetingState);
  const { getVideoStatusAttendee } = useGetStatusAttendee();
  const [isNotifyUserLeft, setIsNotifyUserLeft] = React.useState<boolean>(false);
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const [isTwoUserJoined, setIsTwoUserJoined] = React.useState<boolean>(false);
  const [readyOmiai, setReadyOmiai] = React.useState<boolean>(false);
  const ref = useRef<NodeJS.Timer>();
  const partnerTileId = React.useMemo(() => {
    // お相手のTitleId
    let data;
    for (var i = 0; i < attendees?.length; i++) {
      if (attendees[i]?.chimeAttendeeId !== state_meeting?.attendeeInfo.AttendeeId) {
        if (
          attendees[i]?.externalUserId === state_meeting?.zwei_meeting_info?.member_id ||
          attendees[i]?.externalUserId === state_meeting?.zwei_meeting_info?.partner_id
        ) {
          data = getVideoStatusAttendee(attendees[i]?.chimeAttendeeId);
          break;
        }
      }
    }
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees]);

  // React.useEffect(() => {
  //   console.log('Roster changed', roster);
  // }, [roster]);

  React.useEffect(() => {
    const partner_id = attendees.find(
      (attendee) => attendee.externalUserId === state_meeting?.zwei_meeting_info?.partner_id,
    );
    if (ref.current && !partner_id) {
      clearInterval(ref.current);
    }
    if (checkStartOmiai()) {
      setIsTwoUserJoined(true);
    } else {
      setIsTwoUserJoined(false);
    }
    checkOneUserLeft();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attendees, state_meeting?.zwei_meeting_info?.partner_id]);

  React.useEffect(() => {
    if (isTwoUserJoined) {
      ref.current = setInterval(() => {
        if (state_meeting?.zwei_meeting_info?.matchmaking_at) {
          let now = new Date();
          let start_date = new Date(state_meeting?.zwei_meeting_info?.matchmaking_at);
          if (now >= start_date) setReadyOmiai(true);
        }
      }, INTERVAL_TIME);
    }
    return () => {
      if (ref.current) {
        clearInterval(ref.current);
      }
    };
  }, [isTwoUserJoined, state_meeting?.zwei_meeting_info?.matchmaking_at, attendees]);

  const checkStartOmiai = () => {
    if (!state_meeting?.zwei_meeting_info?.matchmaking_at) {
      // console.log('checkStartOmiai return false because data from api not existed');
      return false;
    }
    //atendeeのビデオが準備できているか
    let remote_attendee_ids = meetingManager?.audioVideo
      ?.getRemoteVideoSources()
      .map((i) => i?.attendee?.externalUserId);
    let attendee_ids = remote_attendee_ids?.concat(state_meeting?.zwei_meeting_info?.my_id);
    if (
      !(
        attendee_ids?.includes(state_meeting?.zwei_meeting_info?.member_id) &&
        attendee_ids?.includes(state_meeting?.zwei_meeting_info?.partner_id)
      )
    ) {
      return false;
    }
    return true;
  };

  const checkOneUserLeft = () => {
    const externalUserIds = attendees?.map((attendee) => attendee.externalUserId);
    // console.log('externalUserIds:', externalUserIds);
    if (
      !(
        externalUserIds?.includes(state_meeting?.zwei_meeting_info?.member_id) &&
        externalUserIds?.includes(state_meeting?.zwei_meeting_info?.partner_id)
      )
    ) {
      setIsNotifyUserLeft(true);
    } else {
      setIsNotifyUserLeft(false);
    }
  };

  return {
    readyOmiai,
    isNotifyUserLeft,
    partnerTileId,
  };
}
