import React from 'react';
import '@modules/Meetings/styles/notify-user-left.scss';

function NotifyUserLeft() {
  return (
    <div id="notifyUserLeft">
      <div className="notify-container">お相手が退室しました。</div>
    </div>
  );
}

export default NotifyUserLeft;
