/** @format */

import queryClient from '@helpers/queryClient';
import { lightTheme, MeetingProvider,LoggerProvider, useLogger } from 'amazon-chime-sdk-component-library-react';
import {ConsoleLogger,LogLevel} from 'amazon-chime-sdk-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { RecoilRoot } from 'recoil';
import { ThemeProvider } from 'styled-components';
import ElementConfig from './elementConfig';
import reportWebVitals from './reportWebVitals';
import './styles/global.scss';
import i18n from './translate/i18n';
const logger = new ConsoleLogger('MyLogger', LogLevel.WARN);
ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <ThemeProvider theme={lightTheme}>
        <LoggerProvider logger={logger}>
            <MeetingProvider>
              <I18nextProvider i18n={i18n}>
                <BrowserRouter>
                  <QueryClientProvider client={queryClient}>
                    <ToastContainer />
                    <ElementConfig />
                  </QueryClientProvider>
                </BrowserRouter>
              </I18nextProvider>
            </MeetingProvider>
        </LoggerProvider>
      </ThemeProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
