/** @format */

import { TypeDataRequestMeeting } from '@helpers/types';
import { meetingState } from '@/states/request-meeting';
import { useRecoilState } from 'recoil';
import {
  useMeetingManager,
  // useBackgroundBlur,
  // useBackgroundReplacement,
  useVideoInputs,
  DeviceLabels,
} from 'amazon-chime-sdk-component-library-react';
import { Device, MeetingSessionConfiguration } from 'amazon-chime-sdk-js';
import {
  ConsoleLogger,
  LogLevel,
  // BackgroundReplacementVideoFrameProcessor,
  DefaultVideoTransformDevice,
  VideoFrameProcessor,
  // BackgroundReplacementProcessor,
} from 'amazon-chime-sdk-js';

// Initialize `VideoPriorityBasedPolicy` and export it to use elsewhere.
import React from 'react';
export function useMeeting() {
  const logger = new ConsoleLogger('SDK', LogLevel.INFO);
  const meetingManager = useMeetingManager();
  const { selectedDevice } = useVideoInputs();
  // const { isBackgroundBlurSupported, createBackgroundBlurDevice } = useBackgroundBlur();
  // const { isBackgroundReplacementSupported, createBackgroundReplacementDevice } = useBackgroundReplacement();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [stateMeeting] = useRecoilState(meetingState);
  // prettier-ignore
  const dataMeeting: TypeDataRequestMeeting = {
    attendeeInfo: {
      AttendeeId: stateMeeting?.attendeeInfo?.AttendeeId || '',
      ExternalUserId: stateMeeting?.attendeeInfo?.ExternalUserId || '',
      JoinToken: stateMeeting?.attendeeInfo?.JoinToken || '',
      BackUrl: "",
    },
    meetingInfo: {
      MeetingId: stateMeeting?.meetingInfo?.MeetingId || '',
      MediaRegion: stateMeeting?.meetingInfo?.MediaRegion || '',
      MediaPlacement: {
        AudioFallbackUrl: stateMeeting?.meetingInfo?.MediaPlacement?.AudioFallbackUrl || '',
        AudioHostUrl: stateMeeting?.meetingInfo?.MediaPlacement?.AudioHostUrl || '',
        ScreenDataUrl: stateMeeting?.meetingInfo?.MediaPlacement?.ScreenDataUrl || '',
        ScreenSharingUrl: stateMeeting?.meetingInfo?.MediaPlacement?.ScreenSharingUrl || '',
        ScreenViewingUrl: stateMeeting?.meetingInfo?.MediaPlacement?.ScreenViewingUrl || '',
        SignalingUrl: stateMeeting?.meetingInfo?.MediaPlacement?.SignalingUrl || '',
        TurnControlUrl: stateMeeting?.meetingInfo?.MediaPlacement?.TurnControlUrl || '',
        EventIngestionUrl: stateMeeting?.meetingInfo?.MediaPlacement?.EventIngestionUrl || ''
      },
    }
  };

  const joinMeeting = async () => {
    setLoading(true);
    // Fetch the meeting and attendee data from your server application
    // console.log('data in MeetingSessionConfiguration:', dataMeeting);
    if (dataMeeting && dataMeeting.meetingInfo && dataMeeting.meetingInfo.MeetingId) {
      // prettier-ignore
      const meetingSessionConfiguration = new MeetingSessionConfiguration(
        {
          "Meeting": {
            "MeetingId": dataMeeting?.meetingInfo?.MeetingId ,
            "MediaPlacement": {
                "AudioHostUrl": dataMeeting?.meetingInfo?.MediaPlacement?.AudioHostUrl,
                "AudioFallbackUrl": dataMeeting?.meetingInfo?.MediaPlacement?.AudioFallbackUrl,
                "ScreenDataUrl": dataMeeting?.meetingInfo?.MediaPlacement?.ScreenDataUrl,
                "ScreenSharingUrl": dataMeeting?.meetingInfo?.MediaPlacement?.ScreenSharingUrl,
                "ScreenViewingUrl": dataMeeting?.meetingInfo?.MediaPlacement?.ScreenViewingUrl,
                "SignalingUrl": dataMeeting?.meetingInfo?.MediaPlacement?.SignalingUrl,
                "TurnControlUrl": dataMeeting?.meetingInfo?.MediaPlacement?.TurnControlUrl,
                "EventIngestionUrl": dataMeeting?.meetingInfo?.MediaPlacement?.EventIngestionUrl
            },
            "MediaRegion": dataMeeting?.meetingInfo?.MediaRegion
        }
        },
        {
          "Attendee": {
            "ExternalUserId": dataMeeting?.attendeeInfo?.ExternalUserId,
            "AttendeeId": dataMeeting?.attendeeInfo?.AttendeeId,
            "JoinToken": dataMeeting?.attendeeInfo?.JoinToken
        }
        }
      );
      await meetingManager.join(meetingSessionConfiguration);
      await meetingManager.start();
      // 最初はmnuteしておく
      meetingManager.meetingSession?.audioVideo?.realtimeMuteLocalAudio();
      setLoading(false);
    } else {
      console.error('dataMeeting undefined');
    }

    // set背景画像
    // const processors: BackgroundReplacementProcessor[] = [];
    // if (isBackgroundReplacementSupported) {
    //   console.log('isBackgroundReplacementSupported');
    //   try {
    //     let current = await createBackgroundReplacementDevice(selectedDevice as Device);
    //     console.log(current);
    //     const image = await fetch('/assets/images/light_blu.png');
    //     const imageBlob = await image.blob();
    //     const options = { imageBlob };
    //     const replacementProcessor = await BackgroundReplacementVideoFrameProcessor.create(undefined, options)!;
    //     if (typeof replacementProcessor !== 'undefined') {
    //       processors.push(replacementProcessor);
    //       let transformDevice = new DefaultVideoTransformDevice(logger, meetingManager.selectedVideoInputDevice as Device, processors);
    //       await meetingManager.selectVideoInputDevice(transformDevice);
    //     }
    //   } catch (error) {
    //     // Handle device selection failure here
    //     console.error('Failed to toggle Background Replacement');
    //   }
    // } else if (isBackgroundBlurSupported) {
    //   //set 背景ぼかし
    //   try {
    //     let current = await createBackgroundBlurDevice(meetingManager.selectedVideoInputDevice as Device);
    //     await meetingManager.selectVideoInputDevice(current);
    //   } catch (error) {
    //     // Handle device selection failure here
    //     console.error('Failed to toggle Background Blur');
    //   }
    // }
  };
  const startMeeting = async () => {
    await meetingManager.start();
    setLoading(false);
  };
  const leaveMeeting = async () => {
    await meetingManager.leave();
  };
  return {
    joinMeeting,
    startMeeting,
    loading,
    leaveMeeting,
  };
}
