import { selector } from 'recoil';
import { globalState } from './globalState';

const LANGUAGE_STATE = 'LANGUAGE_STATE';
export const languageState = selector<string | null>({
  key: LANGUAGE_STATE,
  get: ({ get }) => {
    const stateGlobal = get(globalState);
    return stateGlobal.language;
  },
  set: ({ get, set }, newValue) => {
    const state = get(globalState);
    const newLanguage = newValue as string;
    set(globalState, { ...state, language: newLanguage });
  },
});
