/** @format */

export interface ENV {
  apiEndPoint: string;
  apiEndPointAws: string;
  apiEndPointUrlImage: string;
  username: string;
  password: string;
  pageTitle: string;
  tokenKey: string;
  token: string;
  timeout: string;
  urlFrontend: string;
}

const env: ENV = {
  apiEndPoint: process.env.REACT_APP_API_END_POINT + '/api/v1' || '',
  apiEndPointAws: process.env.REACT_APP_API_END_POINT_AWS || '',
  pageTitle: process.env.REACT_APP_PAGE_TITLE || '',
  tokenKey: process.env.REACT_APP_API_TOKEN_KEY || '',
  token: process.env.REACT_APP_TOKEN || '',
  apiEndPointUrlImage: process.env.REACT_APP_API_END_POINT_URL_IMAGE || '',
  timeout: process.env.REACT_APP_API_TIMEOUT || '',
  username: process.env.REACT_APP_API_BASIC_AUTH_USERNAME || '',
  password: process.env.REACT_APP_API_BASIC_AUTH_PASSWORD || '',
  urlFrontend: process.env.REACT_APP_API_END_POINT || '',
};

export default env;
