import { userInfoState } from '@/states/user-info';
import '@modules/Meetings/styles/profile.scss';
import React from 'react';
import { useRecoilState } from 'recoil';

function ProfilePage() {
  const [userInfo] = useRecoilState(userInfoState);
  const [url, setUrl] = React.useState<string>('');
  const [isText, setIsText] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (userInfo && userInfo.partner && userInfo.partner.avatar) {
      setUrl(userInfo.partner.avatar);
    } else {
      setUrl('');
    }
  }, [userInfo]);

  const onClickCallPhone = () => {
    window.open(`tel:${userInfo?.staff_phone_number}`);
  };

  const onTextCall = () => {
    setIsText(true);
  };

  return (
    <div id="profilePage">
      <div className="profile-container-top">
        <h3 className="text-center">待機画面</h3>
        <p className="text-center">開始時間になるまでお待ちください。<br />お二人が入室されましたら開始されます。</p>
      </div>
      <div className="profile-container-center">
        <div className="img-container">
          {url.length > 0 && <img src={url} className="img-fluid" alt="img-profile" />}
          {userInfo?.partner?.name && <h4>{userInfo?.partner?.name}さん</h4>}
        </div>
      </div>
      {userInfo?.staff_phone_number && (
        <div onClick={onClickCallPhone} className="profile-container-bottom button-call">
          <h4>お相手が入室しないので担当スタッフに連絡する</h4>
        </div>
      )}
      {userInfo?.staff_phone_number && (
        <div className="profile-container-bottom button-text-call" onClick={onTextCall}>
          {isText ? userInfo?.staff_phone_number : 'お相手が入室しないので担当スタッフに連絡する'}
        </div>
      )}
    </div>
  );
}

export default ProfilePage;
