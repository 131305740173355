import moment from 'moment';
import { Time, RequestIdInfo } from './types';

export const TIME_START_KEY = 'time_start';

export const getTimeStart = (requestId: string) => {
  let requestIdInfo = {} as RequestIdInfo;
  let arrayRequestIdInfo: RequestIdInfo[];
  if (localStorage.getItem(TIME_START_KEY)) {
    if (localStorage.getItem(TIME_START_KEY) === '[object Object]') {
      localStorage.removeItem(TIME_START_KEY);
    } else {
      const localStorageValue = JSON.parse(localStorage.getItem(TIME_START_KEY) || '');
      if (!Array.isArray(localStorageValue) && typeof localStorageValue === 'object')
        localStorage.removeItem(TIME_START_KEY);
    }
  }
  if (!localStorage.getItem(TIME_START_KEY)) {
    requestIdInfo.requestId = requestId;
    requestIdInfo.timeStart = moment().toObject();
    arrayRequestIdInfo = [requestIdInfo];
    localStorage.setItem(TIME_START_KEY, JSON.stringify(arrayRequestIdInfo));
  } else {
    arrayRequestIdInfo = JSON.parse(localStorage.getItem(TIME_START_KEY) || '');
    requestIdInfo = arrayRequestIdInfo.find((requestidInfo) => requestidInfo.requestId === requestId) as RequestIdInfo;
    if (!requestIdInfo) {
      requestIdInfo = { requestId: requestId, timeStart: moment().toObject() };
      localStorage.setItem(TIME_START_KEY, JSON.stringify([requestIdInfo, ...arrayRequestIdInfo]));
    } else {
      if (diffSecondsFromNow(objectTimeAddHalfHour(requestIdInfo.timeStart)) < 0) {
        arrayRequestIdInfo = arrayRequestIdInfo.filter((info) => info.requestId !== requestIdInfo.requestId);
        requestIdInfo = { ...requestIdInfo, timeStart: moment().toObject() };
        localStorage.setItem(TIME_START_KEY, JSON.stringify([requestIdInfo, ...arrayRequestIdInfo]));
      }
    }
  }
  return requestIdInfo;
};

export const clearLocalStorage = (requestId?: string) => {
  if (!localStorage.getItem(TIME_START_KEY)) {
    return;
  } else {
    if (localStorage.getItem(TIME_START_KEY) === '[object Object]') {
      localStorage.removeItem(TIME_START_KEY);
      return;
    } else {
      const arrayRequestIdInfo = JSON.parse(localStorage.getItem(TIME_START_KEY) || '');
      if (!Array.isArray(arrayRequestIdInfo) && typeof arrayRequestIdInfo === 'object') {
        localStorage.removeItem(TIME_START_KEY);
        return;
      }
      const arrayRequestIdInfoClearedById = requestId
        ? arrayRequestIdInfo.filter((requestIdInfo: RequestIdInfo) => requestIdInfo.requestId !== requestId)
        : arrayRequestIdInfo;
      if (arrayRequestIdInfoClearedById.length === 0) {
        localStorage.removeItem(TIME_START_KEY);
        return;
      }
      const arrayRequestIdInfoClearedByTime = arrayRequestIdInfoClearedById.filter(
        (requestIdInfo: RequestIdInfo) => diffSecondsFromNow(objectTimeAddHalfHour(requestIdInfo.timeStart)) > 0,
      );
      if (arrayRequestIdInfoClearedByTime.length === 0) {
        localStorage.removeItem(TIME_START_KEY);
        return;
      }
      localStorage.setItem(TIME_START_KEY, JSON.stringify(arrayRequestIdInfoClearedByTime));
    }
  }
};

export const objectTimeAddHalfHour = (timeStartObject: Time) => {
  return moment(timeStartObject).add(30, 'm').toObject();
};

export const convertTimeEndToObject = (time_end: string) => {
  const [date, time] = time_end.split('_');
  const stringDateTime = date.concat(' ', time.replace(/-/g, ':'));
  return moment(stringDateTime).toObject();
};

export const diffSecondsFromNow = (timeEndObject: Time) => {
  return moment(timeEndObject).diff(moment(), 'seconds');
};

export const convertSecondsToMinutesSeconds = (seconds: number) => {
  return { minutes: Math.floor(seconds / 60), seconds: seconds % 60 };
};
