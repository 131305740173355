import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

export function useGetStatusAttendee() {
  const audioVideo = useAudioVideo();
  const tiles = audioVideo?.getAllVideoTiles();

  const getVideoStatusAttendee = (attendeeId: string) => {
    const localAttendeeId = (audioVideo as any)?.audioVideoController?.realtimeController?.state?.localAttendeeId;
    const isLocalUser = attendeeId === localAttendeeId;
    const videoTile = tiles?.find((tile) => {
      const state = tile.state();
      if (state.isContent || (isLocalUser && !state.active)) {
        return false;
      }
      return state.boundAttendeeId === attendeeId;
    });
    return videoTile ? videoTile.state().tileId : null;
  };
  const getTilesId = (attendeeId: string) => {
    const tiles = audioVideo?.getAllVideoTiles();
    const objTiles = tiles?.find((tile) => {
      const state = tile.state();
      return state.boundAttendeeId === attendeeId;
    });
    return objTiles?.state().tileId;
  };
  return { getVideoStatusAttendee, getTilesId };
}
