/** @format */

import { TypeStatusDevices } from '@helpers/types';
import React from 'react';
import { SpinnerCircularFixed } from 'spinners-react';
import './button-check.scss';
interface IProps {
  text?: string;
}
function ButtonCheck(props: IProps) {
  const { text } = props;

  if (text === TypeStatusDevices.LOADING) {
    return (
      <div className="d-flex flex-row align-items-center">
        <SpinnerCircularFixed
          size={30}
          thickness={210}
          speed={210}
          color="rgb(0, 153, 67)"
          secondaryColor="rgba(0, 153, 67, 0.3)"
          className="loading"
        />
        <p>確認中</p>
      </div>
    );
  }
  return (
    <button
      className="button-check"
      style={{
        backgroundColor: text === TypeStatusDevices.WAIT ? '#aaa' : text === TypeStatusDevices.OK ? '#5dd197' : 'red',
      }}
    >
      {text}
    </button>
  );
}

export default ButtonCheck;
