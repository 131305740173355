/** @format */
import env from '@/env';

import './header.scss';
function HeaderLayout() {
  return (
    <header id="headerLayout">
      <div className="container-meeting">
        <div className="zwei-meeting">
          <a href={env.urlFrontend}>
            <img className="img-logo" src="/assets/images/header_logo.png" alt="logo" />
          </a>
        </div>
      </div>
    </header>
  );
}

export default HeaderLayout;
