/** @format */

const LOGIN_PATH = '/login';
const MEETING_CONFIRM_PATH = '/';
const MEETING_START_PATH = '/meeting-start/:id';

export const CommonPath = {
  LOGIN_PATH,
  MEETING_CONFIRM_PATH,
  MEETING_START_PATH,
};
