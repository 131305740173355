/** @format */

import { useRoutes } from "react-router-dom";
import { routes } from "./routes";

function ElementConfig() {
  const element = useRoutes(routes);
  // const navigate = useNavigate();

  // React.useEffect(() => {
  //   navigate("/");
  //   // eslint-disable-next-line
  // }, []);

  return <>{element}</>;
}

export default ElementConfig;
